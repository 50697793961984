/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppAuthenticatedCustomerAuthorizations,
  AppInquiryFullGql_Customer,
  AppInquiryResponse_Question,
  AppInquiryResponse_Section,
} from '@mabadive/app-common-model';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { LoadableContent } from 'src/business/_core/data/app-loading';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAppRouter } from 'src/business/_core/data/hooks/useAppRouter';
import { DiverBookingCriteria } from 'src/business/_core/data/store-repository/diver-booking/model';
import {
  AppBreadcrumbItem,
  AppIconsAction,
} from 'src/business/_core/modules/layout';
import {
  useAppSecurityUser,
  useAppSecurityUserDiverAuthorizations,
} from 'src/business/auth/services';
import { MQueryDescription, graphqlClient } from '../../../../_common-browser';
import {
  DiveCenterBookingPageAggregatedBookingResume,
  DiveCenterBookingPageLoadedContent,
  DiveCenterBookingPageUpdateState,
} from '../DiveCenterBookingPage/model';
import { diveCenterBookingPageAggregatedBookingResumeBuilder } from '../DiveCenterBookingPage/services';
import { useDiveCenterBookingPageLoadedContent } from '../DiveCenterBookingPage/useDiveCenterBookingPageLoadedContent.hook';
import { DiveCenterBookingInquiryPage_FormModel } from './model';
import { DiveCenterBookingInquiryPage_ViewMode } from './model/DiveCenterBookingInquiryPage_ViewMode.type';
export type DiveCenterBookingInquiryPageLocalState = ReturnType<
  typeof useDiveCenterBookingInquiryPageLocalState
>;

export function useDiveCenterBookingInquiryPageLocalState({
  bookingId,
  diverId,
  inquiryId,
  bookingMemberId,
}: DiverBookingCriteria & {
  inquiryId: string;
  bookingMemberId: string;
}) {
  const securityUser = useAppSecurityUser();
  const az: AppAuthenticatedCustomerAuthorizations =
    useAppSecurityUserDiverAuthorizations();

  const {
    content: loadedContent,
    ...loadableContent
  }: LoadableContent<DiveCenterBookingPageLoadedContent> = useDiveCenterBookingPageLoadedContent(
    {
      bookingId,
      diverId,
    },
  );

  const query = useQuery({
    queryKey: [inquiryId],
    queryFn: async () => {
      const q1: MQueryDescription<AppInquiryFullGql_Customer> = {
        returnName: 'inquiry',
        queryName: 'inquiry',
        returnType: 'first',
        where: `{_id: {_eq: "${inquiryId}"}}`,
        returnAttributes: AppInquiryFullGql_Customer,
      };

      const graphqlQuery$ = graphqlClient.query.runMany<{
        inquiry: AppInquiryFullGql_Customer;
      }>([q1], {
        type: 'query',
        name: 'useDiveCenterBookingInquiryPageLocalState',
      });

      const fetchedData = await graphqlQuery$?.toPromise();
      // on converti les données dans un format simple, plus facile à exploiter
      // pour la mise à jour du cache et l'aggrégation des modifications locales

      return fetchedData;
    },
    enabled: !!inquiryId,
    gcTime: 1000 * 60 * 60 * 24 * 2, // on garde ça en cache 2 jours
    staleTime: 60, // on ne rafraichit qu'au bout de 1mn
    refetchOnWindowFocus: false, // mais pas si la fenêtre reprend le focus
    refetchInterval: 30 * 60 * 1000, // et toutes les 30 minutes
    // placeholderData: queryKeysMap.current.get(mainDiverId),
  });

  const [isSubmited, setIsSubmited] = useState(false);

  const [bookingMembersIdsInitialOrder, setBookingMembersIdsInitialOrder] =
    useState<string[]>();

  const { dataCore, aggregatedDataCore } =
    diveCenterBookingPageAggregatedBookingResumeBuilder.aggregatedDataCore({
      loadedContent,
      bookingMembersIdsInitialOrder,
      securityUser,
    });

  const [viewState, setViewStateInner] = useState<{
    mode: DiveCenterBookingInquiryPage_ViewMode;
    participantsIndex?: number; // if (mode === 'checkout-edit-members')
  }>({
    mode: 'inquiry-resume',
    // mode: 'checkout-edit-member-submit',
  });

  const setViewState = useCallback(
    (args: {
      mode: DiveCenterBookingInquiryPage_ViewMode;
      participantsIndex?: number; // if (mode === 'checkout-edit-members')
    }) => {
      setViewStateInner(args);
    },
    [],
  );

  const initialBookingResume: DiveCenterBookingPageAggregatedBookingResume =
    useMemo(
      () =>
        (aggregatedDataCore?.bookingResumes ?? []).find(
          (x) => x.booking?._id === bookingId,
        ),
      [aggregatedDataCore?.bookingResumes, bookingId],
    );

  // const inquiriesByMember = useMemo(
  //   () =>
  //     cuAppInquiriesByMemberBuilder.buildInquiriesByMemberModel({
  //       aggregatedBookingResume: initialBookingResume,
  //       clubResume: initialBookingResume?.clubResume,
  //     }),
  //   [initialBookingResume],
  // );

  // const inquiry = inquiriesByMember
  //   ?.find((x) => x.bookingMemberFull.bookingMember._id === bookingMemberId)
  //   ?.activeInquiries?.find((x) => x._id === inquiryId);

  const inquiry = query?.data?.inquiry;

  useEffect(() => {
    if (
      initialBookingResume?.bookingMembersIdsInitialOrder &&
      !bookingMembersIdsInitialOrder
    ) {
      setBookingMembersIdsInitialOrder(
        initialBookingResume?.bookingMembersIdsInitialOrder,
      );
    }
  }, [
    bookingMembersIdsInitialOrder,
    initialBookingResume?.bookingMembersIdsInitialOrder,
  ]);

  const initialFormValue: DiveCenterBookingInquiryPage_FormModel =
    useMemo(() => {
      const value: DiveCenterBookingInquiryPage_FormModel = {
        inquiryId: inquiry?._id,
        inquiryDetails: {
          name: inquiry?.name,
          type: inquiry?.type,
          settings: inquiry?.settings,
        },
        responseLanguageCode: 'fr', // TODO gérer la localisation
        responseSections: (inquiry?.sections ?? []).map((s) => {
          const formSection: AppInquiryResponse_Section = {
            sectionId: s.sectionId,
            description: s.description,
            questions: s.questions.map((q) => {
              const formQuestion: AppInquiryResponse_Question = {
                questionId: q.questionId,
                description: q.description,
                answer: {
                  format: q.answerDetails.format,
                },
              };
              return formQuestion;
            }),
          };
          return formSection;
        }),
      };
      return value;
    }, [
      inquiry?._id,
      inquiry?.name,
      inquiry?.sections,
      inquiry?.settings,
      inquiry?.type,
    ]);

  const form = useForm<DiveCenterBookingInquiryPage_FormModel>({
    values: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const formValue = useWatch({
    control: form.control,
  }) as unknown as DiveCenterBookingInquiryPage_FormModel;

  const [updateState, setUpdateState] =
    useState<DiveCenterBookingPageUpdateState>({
      diversChanges: [],
      clubParticipantsChanges: [],
      bookingsChanges: [],
      bookingSessionsChanges: [],
      bookingMembersChanges: [],
      bookingSessionParticipantsChanges: [],
      bookingProductsChanges: [],
    });

  const hasAnyChange = useMemo(() => {
    const {
      diversChanges,
      clubParticipantsChanges,
      bookingsChanges,
      bookingSessionsChanges,
      bookingMembersChanges,
      bookingSessionParticipantsChanges,
      bookingProductsChanges,
    } = updateState;

    const changesCount =
      diversChanges?.length +
      clubParticipantsChanges?.length +
      bookingsChanges?.length +
      bookingSessionsChanges?.length +
      bookingMembersChanges?.length +
      bookingSessionParticipantsChanges?.length +
      bookingProductsChanges?.length;
    return changesCount !== 0;
  }, [updateState]);

  const aggregatedData =
    diveCenterBookingPageAggregatedBookingResumeBuilder.aggregateAll({
      dataCore,
      updateState,
      bookingMembersIdsInitialOrder,
      securityUser,
    });

  const { scrollToTop: scrollToTopCore } = useAppRouter();
  const scrollToTop = useCallback(() => {
    scrollToTopCore('app-page-content-container', {});
    // window.document.getElementById('app-page-content-container')?.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: 'auto',
    // });
  }, [scrollToTopCore]);

  const aggregatedBookingResume = useMemo(
    () =>
      aggregatedData.bookingResumes.find(
        (x) => x.booking?._id === initialBookingResume?.booking?._id,
      ),
    [aggregatedData.bookingResumes, initialBookingResume?.booking?._id],
  );
  const clubSettings = aggregatedBookingResume?.clubResume?.clubSettings;
  const clubResume = aggregatedBookingResume?.clubResume;
  const editableParticipantsCount =
    initialBookingResume?.editableParticipantsCount;

  const breadcrumbItems: AppBreadcrumbItem[] = useMemo(() => {
    const items: AppBreadcrumbItem[] = [
      {
        icon: AppIconsAction.checkout,
        label: 'Formulaire',
        url: aggregatedBookingResume?.links.bookingCheckoutUrl,
        onClick: () => setViewState({ mode: 'inquiry-resume' }),
      },
    ];
    // if (viewState.mode === 'checkout-edit-members') {
    //   items.push({
    //     icon: AppIconsAction.addUser,
    //     label: 'Participants',
    //   });
    // }

    return items;
  }, [aggregatedBookingResume?.links.bookingCheckoutUrl, setViewState]);

  const formState = form.formState;

  const redirectTo = useRedirect();

  return {
    state: {
      securityUser,
      az,
      updateState,
      setUpdateState,
      viewState,
      setViewState,
      hasAnyChange,
      isSubmited,
      setIsSubmited,
      clubSettings,
      clubResume,
      breadcrumbItems,
      form,
      formValue,
    },
    data: {
      inquiry,
      loadableContent,
      aggregatedData,
      initialBookingResume,
      aggregatedBookingResume,
    },
    actions: {
      scrollToTop,
    },
  };
}
